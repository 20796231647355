<template>
  <b-card no-body>
    <b-card-header>
      <b-row
        align-v="center"
        class="w-100 m-0"
      >
        <b-col>
          <h4>
            {{ `ประวัติบันทึกการชำระ ${total} รายการ` }}
            <b-button
              variant="flat-primary"
              class="btn-icon"
              size="sm"
              @click="fetchData"
            >
              <feather-icon
                icon="RefreshCcwIcon" 
                size="18"
              />
            </b-button>
          </h4>
        </b-col>
        <b-col
          cols="3"
          class="pr-0"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="search"
              type="search"
              :placeholder="`ชื่อตัวแทน`"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <div />
    </b-card-header>

    <hr class="m-0">
    <b-table
      ref="billSelectableTable"
      :items="dataList"
      :fields="fields"
      :busy="isFetching"
      responsive
      show-empty
      hover
    >
      <template #cell(invoiceCreatedAt)="data">
        {{ data.value | date }}
      </template>
      <template #cell(totalAmount)="data">
        {{ data.value | currency }}
      </template>
      <template #cell(payAmount)="data">
        {{ data.value | currency }}
        <b-button
          variant="flat-primary"
          size="sm"
          @click="viewSlip(data.item.id)"
        >
          <feather-icon
            size="22"
            icon="ImageIcon"
          />
        </b-button>
      </template>
      <template #cell(afterPayAmount)="data">
        {{ data.value | currency }}
      </template>
      <template #cell(id)="data">
        <b-button
          variant="flat-danger"
          size="sm"
          @click="onRemove(data.item)"
        >
          <feather-icon
            size="22"
            icon="Trash2Icon"
          />
        </b-button>
      </template>
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>
      <template #empty="">
        <div class="my-5">
          <p class="text-center text-muted">
            ไม่มียอด
          </p>
        </div>
      </template>
    </b-table>
    <ViewSlipModal :payment-id="selectedPaymentId" />
    <RemovePaymentModal
      :payment="selectedPayment"
      @refetch="fetchData"
    />
    <!-- pagination -->
    <b-card-footer>
      <b-row class="">
        <b-col md="4">
          <page-limit-select
            :value="limit"
            @update="onLimitChange"
          />
        </b-col>
        <b-col>
          <pagination-input
            :per-page="limit"
            :total="total"
            @update="(val) => (currentPage = val)"
          />
        </b-col>
      </b-row>
    </b-card-footer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'PaymentsHistory', 
  components: {
    ViewSlipModal: () => import('./ViewSlipModal.vue'),
    RemovePaymentModal: () => import('./RemovePaymentModal.vue'),
  },
  data() {
    return {
      currentPage: 1,
      selectedLimit: 20,

      search: '', 
      onSearchTimeout: null,

      fields: [
          {
          key: 'invoiceCreatedAt',
          label: 'วันที่บันทึกยอด',
          thStyle: {
            minWidth: '150px',
          },
        },
         {
          key: 'ref',
          label: 'เลขที่อ้างอิง',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'agentName',
          label: 'ตัวแทน',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'totalAmount',
          label: 'ยอดก่อนบันทึก (บาท)',
          class: 'text-right',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'payAmount',
          label: 'จำนวนที่บันทึก (บาท)',
          class: 'text-right',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'afterPayAmount',
          label: 'ยอดหลังบันทึก (บาท)',
          class: 'text-right',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'operatorName',
          label: 'ผู้ดำเนินการ',
          class: 'text-right',
          thStyle: {
            minWidth: '200px',
          },
        },
        {
          key: 'id',
          label: '',
          thStyle: {
            minWidth: '100px',
          },
          class: 'text-right',
        },
      ],

      selectedPaymentId: null,
      selectedPayment: null,
    }
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.payment.isGettingPayments,
    }),
    ...mapGetters(['payments']),
    dataList() {
      return this.payments?.data || []
    },
    pagination() {
      const { totalItems, perPage } = this.payments;
      return {
        itemsPerPage: perPage || this.selectedLimit,
        totalItems,
      }
    },
    limit() {
      return this.pagination.itemsPerPage
    },
    total() {
      return this.pagination.totalItems
    },
  },
  watch: { 
    currentPage(val) {
      if (val) {
        this.$addPageToLocation(val)
        this.fetchData()
      }
    },
    search() {
      this.delaySearch()
    },
  },
  created() {
  },
  methods: {
    ...mapActions(['getPayments']),
    fetchData() {
      this.getPayments({
        limit: this.selectedLimit,
        skip: this.currentPage,
        search: this.search,
      });
    },
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
    onLimitChange(limit) {
      if (limit) {
        this.selectedLimit = limit
        this.fetchData()
      }
    },
    viewSlip(paymentId) {
      // TODO
      this.selectedPaymentId = paymentId
      this.$bvModal.show('view-slip-modal')
    },
    onRemove(payment){
      this.selectedPayment = payment
      this.$bvModal.show('remove-payment-modal')
    }
  },
}
</script>
 